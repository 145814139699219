@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/* Core Styles */
:root {
  --primary-color: #2C5530;
  --secondary-color: #3A6B3D;
  --accent-color: #FFD700;
  --text-color: #333;
  --text-light: #666;
  --background-light: #f8f9fa;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDelay {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(1.1);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Animation Classes */
.animate-fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.animate-fade-in-delay {
  animation: fadeInDelay 2s ease-out forwards;
}

.animate-scale-in {
  animation: scaleIn 1.5s ease-out forwards;
}

/* Hover Effects */
.hover-lift {
  transition: transform 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-light);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

@layer components {
  .btn-primary {
    @apply inline-flex items-center justify-center px-8 py-3 bg-primary text-white font-semibold rounded-full
    hover:-translate-y-0.5 hover:shadow-lg transition-all duration-300;
  }

  .btn-secondary {
    @apply inline-flex items-center justify-center px-8 py-3 bg-white text-primary font-semibold rounded-full
    border-2 border-primary hover:-translate-y-0.5 hover:shadow-lg transition-all duration-300;
  }

  .btn-dark {
    @apply inline-flex items-center justify-center px-8 py-3 bg-white/10 text-white font-semibold rounded-full
    border-2 border-white hover:bg-white/20 hover:-translate-y-0.5 hover:shadow-lg transition-all duration-300;
  }

  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}

/* Additional global styles */
body {
  @apply font-primary text-base text-gray-900 antialiased;
}

/* Add italic text utility class */
.text-body {
  @apply font-primary italic;
}

.main-content {
  position: relative;
  padding: 0;
  margin: 0;
}
